import React from 'react';
import { RadioSDK } from '@tugboatholdings/radio-sdk-react-js';

const ExecuteFlowScreen = () => {
  return (
    <>
      <RadioSDK />
    </>
  );
};

export default ExecuteFlowScreen;
