import React from 'react';
import { ThemeProvider } from '@material-ui/core';
import theme from './Theme/theme';
import ExecuteFlowCasesComponent from './pages/ExecuteFlowScreen';
import PasswordForm from './pages/PasswordForm';

const App = () => {

  const [authenticated, setAuthenticated] = React.useState<boolean>(false);
  const [showAuthPage, setShowAuthPage] = React.useState<boolean>(process.env.REACT_APP_USE_DEV_AUTH === 'true');

  const isLocalEnv = ['localhost', '127.0.0.1', ''].includes(window.location.hostname);

  React.useEffect(()=>{
    setShowAuthPage(!isLocalEnv && !authenticated && process.env.REACT_APP_USE_DEV_AUTH === 'true');
  }, [isLocalEnv, authenticated ]);

  return (
    <ThemeProvider theme={theme}>
      { (showAuthPage) ? 
        (
          <PasswordForm setAuthenticated={setAuthenticated} />
        ):(
          <ExecuteFlowCasesComponent />
        ) }
    </ThemeProvider>
  );
};

export default App;
