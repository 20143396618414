import React, { Dispatch, SetStateAction } from 'react';

import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { 
  makeStyles,
  createStyles
} from '@material-ui/core/styles';

interface PasswordFormProps {
  setAuthenticated: Dispatch<SetStateAction<boolean>>;
}

const PasswordForm: React.FC<PasswordFormProps> = ({ setAuthenticated }) => {

  const classes = useStyles();

  const pParam = window.location.search.replace('?p=', '');
  const simplePw = '2020Tugboat!';
  const [password, setPassword] = React.useState<string>(pParam);
  const [error, setError] = React.useState<boolean>(false);
  const errorMessage = 'Invalid password';

  const onSubmit = ((e: any) => {
    e.preventDefault();

    if (password === simplePw) {
      setAuthenticated(true);
    } else {
      setError(true);
    }
  });


  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Typography component="h1" variant="h5">
          Experience Engine
        </Typography>
        <form className={classes.form} noValidate onSubmit={onSubmit}>
          <TextField
            error={error}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(e: any) => {
              setError(false);
              setPassword(e.currentTarget.value);
            }}
            helperText={error && errorMessage}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Sign In
          </Button>
        </form>
      </div>
    </Container>
  );
};

const useStyles = makeStyles(theme => 
  createStyles({
    paper: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
  }));

export default PasswordForm;
