import { createMuiTheme } from '@material-ui/core';
import '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#063F34',
      dark: '#001A0D',
      light: '#1D6A5B',
      '900': '#063F34',
      '400': '#1D6A5B',
    },
    secondary: {
      main: '#ED5454',
      dark: '#C74646',
      light: '#EE6262',
      '900': '#A13939',
      '500': '#ED5454',
      '700': '#C74646',
      '400': '#EE6262',
      '100': '#F6A6A6',
    },
  },
  typography: { fontFamily: ['Graphik'].join(',') },
  overrides: {
    MuiBackdrop: {
      root: {
        backgroundColor: 'rgba(6, 63, 54,0.7)',
      },
    },
  },
});

export default theme;
